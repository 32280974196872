interface Props {
  url: string;
  styles: string;
  text: string;
  language: string;
}

const UrlComponent = ({ url, styles, text }: Props) => {
  const preparedLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const preparedUrlParams = urlParams.size !== 0 ? `?${urlParams}` : '';
    const newUrl = new URL(`${url}${preparedUrlParams}`, window.location.origin).href;
    return newUrl;
  };
  return (
    <a className={styles} href={preparedLink()}>
      {text}
    </a>
  );
};

export default UrlComponent;
